<template>
  <div>
    <v-card-title class="display-1 mb-2">{{t('$vuetify.serviceCenter.requestCharger')}}</v-card-title>
    <v-card-subtitle><v-checkbox
          v-model="isDiffAdrs"
          :label="`Delivery address is different from the invoice address`"
        ></v-checkbox></v-card-subtitle>
    <v-form ref="form" v-model="isFormValid" lazy-validation>
      <v-card-text v-if="isDiffAdrs">
        <v-row>
          <v-flex xs6>
            <v-text-field
              v-if="payload.isCompany"
              class="mx-2"
              v-model="payload.companyName"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              :label="t('$vuetify.serviceCenter.company')"
              name="company"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
          <v-flex xs6>
            <v-text-field
              v-if="payload.isCompany"
              v-model="payload.vat"
              class="mx-2"
              :rules="[rules.required, rules.vatCheck]"
              :validate-on-blur="false"
              :error="error"
              :label="t('$vuetify.serviceCenter.vatNumber')"
              name="vat_number"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
        </v-row>
        <v-row>
          <v-flex xs3>
            <v-select
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              name="sexe"
              @keyup.enter="submit"
              @change="resetErrors"
              v-model="payload.sexe"
              class="mx-2"
              :items="['Mr.', 'Mrs.', 'Ms']"
              :label="t('$vuetify.serviceCenter.mrMrsms')"
            ></v-select>
          </v-flex>
          <v-flex xs9>
            <v-text-field
              class="mx-2"
              v-model="payload.name"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              :label="t('$vuetify.serviceCenter.name')"
              name="name"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
        </v-row>
        <v-row>
          <v-flex xs12>
            <v-text-field
              class="mx-2"
              v-model="payload.address"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              :label="t('$vuetify.serviceCenter.address')"
              name="address"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
        </v-row>
        <v-row>
          <v-flex xs4>
            <v-text-field
              class="mx-2"
              v-model="payload.zipCode"
              :validate-on-blur="false"
              :rules="[rules.required]"
              :error="error"
              :label="t('$vuetify.serviceCenter.zipCode')"
              name="zip_code"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
          <v-flex xs8>
            <v-text-field
              class="mx-2"
              v-model="payload.city"
              :rules="[rules.required]"
              :validate-on-blur="false"
              :error="error"
              :label="t('$vuetify.serviceCenter.city')"
              name="city"
              @keyup.enter="submit"
              @change="resetErrors"
            ></v-text-field>
          </v-flex>
        </v-row>
      </v-card-text>
    </v-form>
    <v-divider></v-divider>
    <v-card-actions class="justify-end">
      <!-- {{t('$vuetify.serviceCenter.totPrice')}} : {{totPrice}} € -->
      <v-spacer />
      <v-btn
          color="primary"
          text
          @click="previousStep()"
      >
          <v-icon>mdi-chevron-left</v-icon>
          {{t('$vuetify.generic.back')}}
      </v-btn>
      <v-btn
          color="success"
          text
          @click="nextStep()"
      >
        {{t('$vuetify.generic.next')}}
      </v-btn>
      <v-btn
        color="red"
        text
        @click="cancel"
      >
        {{t('$vuetify.generic.cancel')}}
      </v-btn>
    </v-card-actions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserApi from '@/services/UserApi'

export default {
  data () {
    return {
      payload: {
        sexe: 'Mr.',
        companyName: '',
        vat: '',
        name: '',
        address: '',
        addressBis: '',
        zipCode: '',
        city: '',
        country: ''
      },
      isDiffAdrs: false,

      // sign in buttons
      isLoading: false,
      isSignInDisabled: false,

      // form
      isFormValid: true,
      email: '',
      password: '',

      // form error
      error: false,
      errorMessages: '',

      errorProvider: false,
      errorProviderMessages: '',

      // input rules
      rules: {
        required: (value) => (value && Boolean(value)) || 'Required',
        vatCheck: v => {
          if (/^(BE)?0[0-9]{9}$/.test(v)) return true
          return 'invalide vat should be lik BEXXXXXXXXXX'
        },
        zipCode: v => {
          if (!v.trim()) return true
          if (!isNaN(parseFloat(v)) && v >= 0 && v <= 9999) return true
          return 'Number has to be between 0 and 9999'
        },
        email: v => {
          if (/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v)) return true
          return 'Invalid email'
        },
        phone: v => {
          if (/^(?:(?:\+|00)32|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/.test(v)) return true
          return 'Invalid phone should be like 04XXXXXXXX'
        }
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      const dt = await new UserApi().userInfo()
      this.payload.address = dt.data.data.RelationContact.Street + ' ' + dt.data.data.RelationContact.HouseNumber // Street Address (delivery) - string
      this.payload.zipCode = dt.data.data.RelationContact.PostalCode // Postal Code (delivery) - string
      this.payload.city = dt.data.data.RelationContact.City
      this.payload.name = dt.data.data.RelationContact.Name
      this.payload.isCompany = dt.data.data.Relation.IsCompany
      this.payload.companyName = dt.data.data.Relation.Name
      this.payload.vat = dt.data.data.Relation.VatNr
    },
    nextStep () {
      this.submit()
    },
    previousStep () {
      this.$store.commit('requestsState/setPreviousStepName')
    },
    cancel () {
      this.$emit('cancel')
    },
    submit () {
      if (this.$refs.form.validate()) {
        if (this.isDiffAdrs) {
          this.$store.commit('requestsState/setDeliveryAddress', this.payload)
        } else {
          this.$store.commit('requestsState/setSameDeliveryAddress')
        }
        this.$store.commit('requestsState/setStep', 'ContactDetails')
      }
    },
    resetErrors () {
      this.error = false
      this.errorMessages = ''

      this.errorProvider = false
      this.errorProviderMessages = ''
    }
  },
  computed: {
    ...mapGetters({ price: 'requestsState/price', totPrice: 'requestsState/totalPrice' })
  }
}
</script>
